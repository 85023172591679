import { StateKind } from "@common/state";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAmosOrderResultDto } from "contracts/amos";
import { getOrdersAsync } from "dockScheduler/@components/grid/@core/dockSchedulerOrder";

interface IDockSchedulerOrderState {
    status: StateKind;
    orders: IAmosOrderResultDto;
}

const initialState: IDockSchedulerOrderState = {
    status: "notLoaded",
    orders: {
        results: [],
        totalCount: 0
    },
}

export const dockSchedulerOrderStore = createSlice({
    name: 'dockSchedulerOrderStore',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrdersAsync.pending, (state: IDockSchedulerOrderState) => {
                state.status = 'loading';
            })
            .addCase(getOrdersAsync.fulfilled, (state: IDockSchedulerOrderState, action: PayloadAction<IAmosOrderResultDto>) => {
                state.status = 'loaded';
                state.orders = action.payload;
            })
            .addCase(getOrdersAsync.rejected, (state: IDockSchedulerOrderState, error) => {
                state.status = 'failed';
            })
    }
})

export const DockSchedulerMapAction = dockSchedulerOrderStore.actions;

export default dockSchedulerOrderStore.reducer;